<template lang="pug">
    ui-desktop-mobile-image.schema-components(imageName="schema_components")
</template>

<script>
import UiDesktopMobileImage from '../../../../ui/DesktopMobileImage.vue';

export default {
    name: 'schema-components',
    components: {
        UiDesktopMobileImage,
    },
};
</script>

<style lang="scss" scoped>
.schema-components {
    width: vw(740);
    max-width: 920px;
    margin: 0 auto;

    @media (--viewport-tablet) {
        width: 100%;
        max-width: unset;
        padding: 0 var(--offset-x);
    }
}
</style>
